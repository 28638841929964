import {useEffect, useState} from "react"
import {
    SchemaArticle,
    SchemaCookingMethod,
    SchemaDish,
    SchemaFoodMatch, SchemaFoodMatchCategory, SchemaFoodMatchDish,
    SchemaFoodType,
    SchemaGeoCoordinates,
    SchemaReference,
    SchemaThing,
    SchemaWine,
    SchemaWineManufacturer,
} from "../_types/types"
import {serviceGet} from "../_services/api.public.service"
import {API_URL} from "../_helpers/environment"
import {objectSortByName} from "../_helpers/sorting"
import {
    TypeCategory,
    TypeCountry,
    TypeDiscoveryCase,
    TypeProduct,
    TypePropertyValue,
    TypeWine,
} from "../_types/new-types"
import {includesToQueries} from "../_services/mercury.service"


interface SingleDocumentProps<T> {
    busy: boolean
    document?: T
    error?: any
}

export const useOneDocument = <T>(url: string | undefined) => {
    const [result, setResult] = useState<SingleDocumentProps<T>>({"busy": true})
    useEffect(() => {
        if (url === undefined) {
            setResult({"busy": false, "document": undefined, "error": undefined})
        } else {
            setResult({"busy": true, "document": undefined, "error": undefined})
            serviceGet<T>(
                url,
                () => setResult({"busy": true, "document": undefined}),
                document => setResult({"busy": false, document}),
                error => setResult({"busy": false, "error": error}),
            )
        }
    }, [url])
    return result
}

interface SingleWineHookProps {
    busy: boolean
    included: []
    wine?: TypeWine
    error?: any
}

export const useSingleWine = (id: string, include: { [key: string]: string } | null = null) => {
    const [result, setResult] = useState<SingleWineHookProps>({"busy": true, "included": []})
    useEffect(() => {
        if (id === "") {
            setResult(prev => ({...prev, "busy": false, "wine": undefined, "error": undefined}))
            return
        }
        const queries = (include === null) ? [] : includesToQueries(include)
        setResult(prev => ({...prev, "busy": true, "error": undefined}))
        serviceGet<TypeWine & { "@included"?: [] }>(
            `${API_URL}/documents/wines/${id}?${queries.join("&")}`,
            null,
            wine => setResult({"busy": false, wine, "included": wine["@included"] || []}),
            error => setResult({"busy": false, "error": error, "wine": undefined, "included": []}),
        )
    }, [include, id])
    return result
}

interface SingleWineFromNameHookProps {
    busy: boolean
    reference: SchemaReference
    error?: any
}

export const useSingleWineByName = (name: string): SingleWineHookProps => {
    const [result, setResult] = useState<SingleWineFromNameHookProps>({"busy": true, "reference": {"@id": ""}})
    useEffect(() => {
        setResult(prev => ({...prev, "busy": true, "reference": {"@id": ""}}))
        serviceGet<SchemaReference>(
            API_URL + "/t/wines/" + name,
            null,
            reference => setResult({"busy": false, reference}),
            error => setResult({"busy": false, "error": error, "reference": {"@id": ""}}),
        )
    }, [name])
    const wine = useSingleWine(result.reference["@id"].split("/").pop() || "")
    return {
        "busy": wine.busy || result.busy || (!wine.wine && !wine.error && !result.error),
        "wine": wine.wine,
        "error": wine.error || result.error,
        "included": [],
    }
}

interface SingleManufacturerFromNameHookProps {
    busy: boolean
    reference: SchemaReference
    error?: any
}

interface SingleManufacturerHookProps {
    busy: boolean
    manufacturer?: SchemaWineManufacturer
    error?: any
}

export const useSingleManufacturer = (id: string) => {
    const [result, setResult] = useState<SingleManufacturerHookProps>({"busy": true})
    useEffect(() => {
        if (id === "") {
            setResult(prev => ({...prev, "busy": false, "manufacturer": undefined, "error": undefined}))
            return
        }
        setResult(prev => ({...prev, "busy": true, "error": undefined}))
        serviceGet<SchemaWineManufacturer>(
            API_URL + "/documents/organizations/" + id,
            null,
            m => setResult({"busy": false, "manufacturer": m}),
            error => setResult({"busy": false, "error": error, "manufacturer": undefined}),
        )
    }, [id])
    return result
}

export const useSingleManufacturerByName = (name: string): SingleManufacturerHookProps => {
    const [result, setResult] = useState<SingleManufacturerFromNameHookProps>({"busy": true, "reference": {"@id": ""}})
    useEffect(() => {
        setResult(prev => ({...prev, "busy": true, "reference": {"@id": ""}}))
        serviceGet<SchemaReference>(
            `${API_URL}/documents/manufacturers?filter[identifier]=/${name}/`,
            null,
            reference => setResult({"busy": false, reference}),
            error => setResult({"busy": false, "error": error, "reference": {"@id": ""}}),
        )
    }, [name])
    const manu = useSingleManufacturer(result.reference["@id"].split("/").pop() || "")
    return {
        "busy": manu.busy || result.busy || (!manu.manufacturer && !manu.error && !result.error),
        "manufacturer": manu.manufacturer,
        "error": manu.error || result.error,
    }
}

interface SingleArticleHookProps {
    busy: boolean
    article: SchemaArticle
    error?: any
}

export const useSingleArticle = (id: string) => {
    const [result, setResult] = useState<SingleArticleHookProps>({"busy": true, "article": {} as SchemaArticle})
    useEffect(() => {
        if (id === "") return
        serviceGet<SchemaArticle>(
            API_URL + "/documents/articles/" + id,
            null,
            article => setResult({"busy": false, article}),
            error => setResult({"busy": false, "error": error, "article": {} as SchemaArticle}),
        )
    }, [id])
    return result
}

interface SingleArticleFromNameHookProps {
    busy: boolean
    reference: SchemaReference
    error?: any
}

export const useSingleArticleByName = (name: string): SingleArticleHookProps => {
    const [result, setResult] = useState<SingleArticleFromNameHookProps>({"busy": true, "reference": {"@id": ""}})
    useEffect(() => {
        serviceGet<SchemaReference>(
            API_URL + "/t/articles/" + name,
            null,
            reference => setResult({"busy": false, reference}),
            error => setResult({"busy": false, "error": error, "reference": {"@id": ""}}),
        )
    }, [name])
    const article = useSingleArticle(result.reference["@id"].split("/").pop() || "")
    if (result.error || result.busy) {
        return {
            "busy": result.busy,
            "article": {} as SchemaArticle,
            "error": result.error,
        }
    }
    return article
}

interface SingleDiscoveryCaseHookProps {
    busy: boolean
    discoveryCase: TypeDiscoveryCase
    error?: any
}

export const useSingleDiscoveryCase = (id: string) => {
    const [result, setResult] = useState<SingleDiscoveryCaseHookProps>({
        "busy": true,
        "discoveryCase": {} as TypeDiscoveryCase,
    })
    useEffect(() => {
        serviceGet<TypeDiscoveryCase>(
            API_URL + "/documents/discovery-cases/" + id,
            null,
            discoveryCase => setResult({"busy": false, discoveryCase}),
            error => setResult({"busy": false, "error": error, "discoveryCase": {} as TypeDiscoveryCase}),
        )
    }, [id])
    return result
}

interface ProductHookProps<T> {
    busy: boolean
    list: T[]
    map: { [key: string]: T }
    error?: any
}

const makeMapById = (list: SchemaThing[]) => {
    const map: { [key: string]: any } = {}
    list.filter(item => item["@id"]).forEach(item => map[item["@id"] || ""] = item)
    return map
}

export const useWines = (delay = 50, filter: string[] = []) => {

    const [fetch, setFetch] = useState<boolean>(false)
    const [result, setResult] = useState<ProductHookProps<SchemaWine>>({"busy": true, "list": [], "map": {}})

    useEffect(() => {
        let t = setTimeout(() => setFetch(true), delay)
        return () => {
            clearTimeout(t)
        }
    }, [delay])

    useEffect(() => {
        if (!fetch) return
        setFetch(false)
        let url = `${API_URL}/documents/wines`
        if (filter.length)
            url += `?filter[@id]=/${filter.map(s => `${s}$`).join("|")}/`
        serviceGet<{ "@graph": SchemaWine[] }>(
            url,
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [fetch, filter])

    return result
}

export const useDiscoveryCases = () => {
    const [result, setResult] = useState<ProductHookProps<TypeDiscoveryCase>>({
        "busy": true,
        "list": [],
        "map": {},
    })
    useEffect(() => {
        serviceGet<{ "@graph": TypeDiscoveryCase[] }>(
            API_URL + "/documents/discovery-cases",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useFoodMatches = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaFoodMatch>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": SchemaFoodMatch[] }>(
            API_URL + "/documents/food-matches",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useDishes = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaDish>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": SchemaDish[] }>(
            API_URL + "/documents/dishes",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useFoodTypes = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaFoodType>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": SchemaFoodType[] }>(
            API_URL + "/documents/food-types",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useCookingMethods = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaCookingMethod>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": SchemaCookingMethod[] }>(
            API_URL + "/documents/cooking-methods",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useCountries = (delay = 50) => {

    const [result, setResult] = useState<ProductHookProps<TypeCountry>>({"busy": true, "list": [], "map": {}})
    const [fetch, setFetch] = useState<boolean>(false)

    useEffect(() => {
        let t = setTimeout(() => setFetch(true), delay)
        return () => {
            clearTimeout(t)
        }
    }, [delay])

    useEffect(() => {
        if (!fetch) return
        setFetch(false)
        serviceGet<{ "@graph": TypeCountry[] }>(
            API_URL + "/documents/countries",
            null,
            result => {
                const list = result["@graph"]
                objectSortByName<TypeCountry>(list)
                setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])})
            },
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [fetch])
    return result
}

export const useGrapes = () => {
    const [result, setResult] = useState<ProductHookProps<TypeProduct>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": TypeProduct[] }>(
            API_URL + "/documents/grapes",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useAgriculture = () => {
    const [result, setResult] = useState<ProductHookProps<TypePropertyValue>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": TypePropertyValue[] }>(
            API_URL + "/documents/agriculture",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useBottleCaps = () => {
    const [result, setResult] = useState<ProductHookProps<TypePropertyValue>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": TypePropertyValue[] }>(
            API_URL + "/documents/bottle-caps",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useAgings = () => {
    const [result, setResult] = useState<ProductHookProps<TypePropertyValue>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": TypePropertyValue[] }>(
            API_URL + "/documents/agings",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}

export const useAppellations = (delay = 50) => {
    const [result, setResult] = useState<ProductHookProps<SchemaGeoCoordinates>>({"busy": true, "list": [], "map": {}})
    const [fetch, setFetch] = useState<boolean>(false)

    useEffect(() => {
        let t = setTimeout(() => setFetch(true), delay)
        return () => {
            clearTimeout(t)
        }
    }, [delay])

    useEffect(() => {
        if (!fetch) return
        setFetch(false)
        serviceGet<{ "@graph": SchemaGeoCoordinates[] }>(
            API_URL + "/documents/appellations",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [fetch])
    return result
}

export const useFoodMatchDishCategories = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaFoodMatchCategory>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        if (!fetch) return
        serviceGet<{ "@graph": SchemaFoodMatchCategory[] }>(
            API_URL + "/documents/food-match-dish-categories",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [fetch])
    return result
}

export const useFoodMatchDishes = () => {
    const [result, setResult] = useState<ProductHookProps<SchemaFoodMatchDish>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        if (!fetch) return
        serviceGet<{ "@graph": SchemaFoodMatchDish[] }>(
            API_URL + "/documents/food-match-dishes",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [fetch])
    return result
}

export const useCategories = () => {
    const [result, setResult] = useState<ProductHookProps<TypeCategory>>({"busy": true, "list": [], "map": {}})
    useEffect(() => {
        serviceGet<{ "@graph": TypeCategory[] }>(
            API_URL + "/documents/categories",
            null,
            result => setResult({"busy": false, "list": result["@graph"], "map": makeMapById(result["@graph"])}),
            error => setResult({"busy": false, "error": error, "list": [], "map": {}}),
        )
    }, [])
    return result
}
