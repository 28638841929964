import React, {FC, useMemo, useState} from "react"
import "./FoodMatchPage.sass"
import "../Case/WineCasePage.scss"
import {useFoodMatchDishCategories, useFoodMatchDishes} from "../_hooks/product.hook"
import {SchemaFoodMatchCategory, SchemaFoodMatchDish, SchemaThing} from "../_types/types"
import {TypeAppellation, TypeCategory, TypePropertyValue, TypeReference, TypeWine} from "../_types/new-types"
import {useDocument} from "../_services/mercury.service"
import {findIncludedById} from "../_helpers/included"
import LocaleLink from "../_components/localization/LocaleLink"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCaretDown, faExternalLinkAlt, faRotateLeft, faUtensils} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "react-i18next"

interface WineEntryProps {
    reference: TypeReference
    showDescription?: boolean
}

export const WineEntry: FC<WineEntryProps> = ({reference, showDescription}) => {

    let query = useMemo(() => [
        "include=agriculture,appellation,bottleCap,category",
        "include[appellation]=addressCountry",
        "fields=name,agriculture,appellation,bottleCap,category,image,identifier,description",
    ], [])
    const {
        "document": wine,
        "isLoading": busy,
        error,
    } = useDocument<TypeWine & { "@included": any[] }>(reference["@id"], query)

    if (busy || error || !wine) {
        return null
    }

    const included = wine["@included"]
    const appellation = findIncludedById<TypeAppellation>(wine.appellation, included)
    const country = findIncludedById<TypeAppellation>(appellation?.addressCountry, included)
    const agriculture = findIncludedById<TypePropertyValue>(wine.agriculture, included)
    const bottleCap = findIncludedById<TypePropertyValue>(wine.bottleCap, included)
    const category = findIncludedById<TypeCategory>(wine.category, included)

    return (
        <LocaleLink to={`/wines/${wine.identifier}`} target="_blank" className="case-wine">
            <div className="case-wine-image"
                 style={{"backgroundImage": `url("${(wine.image?.contentUrl)}")`}}/>
            <div className="case-wine-body">
                <div className="case-wine-name">
                    <span className={`icon-country icon-countries-${country?.identifier}`}/>
                    {wine.name}
                    <span className="external-icon">
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2"/>
                        </span>
                </div>
                {wine.description && showDescription ? (
                    <div className="case-wine-description">
                        {wine.description}
                    </div>
                ) : null}
                <div className="case-wine-features">
                    <span data-tooltip={category?.name?.split("/").pop()}
                          className={`icon-wine-category tooltip icon-wine-categories-${category?.identifier}`}/>
                    <span data-tooltip={bottleCap?.value}
                          className={`icon-wine-category tooltip icon-bottle-caps-${bottleCap?.identifier}`}/>
                    <span data-tooltip={agriculture?.value}
                          className={`icon-wine-category tooltip icon-agriculture-${agriculture?.identifier}`}/>
                </div>
            </div>
        </LocaleLink>
    )
}


interface FoodMatchSearchProps {
    dishes: SchemaFoodMatchDish[]
    categories: SchemaFoodMatchCategory[]
    setCategory: (categoryId: string | undefined) => void
    setDish: (dishId: SchemaFoodMatchDish | undefined) => void
}

const FoodMatchSearch: FC<FoodMatchSearchProps> = ({setDish, setCategory, dishes}) => {
    const {t} = useTranslation("common")
    const [dishSearch, setDishSearch] = useState<string>("")
    return (
        <>
            <div className="h3" style={{"marginTop": "1.5rem"}}>{t("fm/searchForDishTitle")}</div>
            <div className="dish-search-container">
                <div className="dish-search">
                    {t("fm/search/prefix", "")}
                    <input value={dishSearch} onChange={e => setDishSearch(e.target.value)}/>
                    {t("fm/search/suffix", "")}
                </div>
            </div>
            <div className="dish-search-results">
                <div className="dish-list">
                    {dishSearch.length > 0 && (
                        dishes.filter(dish => {
                            let strippedName = dish.name
                            strippedName = strippedName.replaceAll(" ", "").toLowerCase()
                            let strippedSearch = dishSearch
                            strippedSearch = strippedSearch.replaceAll(" ", "").toLowerCase()
                            return strippedName.indexOf(strippedSearch) !== -1
                        }).slice(0, 5).map(dish => (
                            <div
                                className={"dish-item"}
                                key={dish["@id"]}
                                onClick={() => {
                                    setDish(dish)
                                    setCategory(dish.category["@id"])
                                }}>
                                {dish.name}
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    )
}

interface FoodMatchCategorySectionProps {
    dishes: SchemaFoodMatchDish[]
    categories: SchemaFoodMatchCategory[]
    selected: string | undefined
    setCategory: (categoryId: string | undefined) => void
    setDish: (dishId: SchemaFoodMatchDish | undefined) => void
}

const CategorySelector: FC<FoodMatchCategorySectionProps> = ({categories, selected, setCategory, ...props}) => {
    const {t} = useTranslation("common")
    return (
        <>
            <div className="h3">{t("fm/cookingCategoryTitle")}</div>
            <div className="container grid-xl">
                <div className="category-container">
                    {categories.slice().sort((a: SchemaFoodMatchCategory, b: SchemaFoodMatchCategory) => {
                        return (a.name?.toLowerCase() || "") < (b.name?.toLowerCase() || "") ? -1 : 1
                    }).map(dish => (
                        <button
                            key={dish["@id"]}
                            type="button"
                            className={`dish-category ${dish["@id"] === selected ? "active" : ""}`}
                            onClick={() => {
                                setCategory(dish["@id"])
                            }}
                        >
                            <span
                                className="tile-icon"
                                style={{"backgroundImage": `url("https://static.matthys-wines.com/images/prod/food-match/food-styles-v2/${dish.identifier}.svg")`}}
                            />
                            {dish.name}
                        </button>
                    ))}
                </div>
                <FoodMatchSearch {...props} categories={categories} setCategory={setCategory}/>
            </div>
        </>
    )
}

const CategoryDisplay: FC<FoodMatchCategorySectionProps> = ({categories, selected, setCategory, setDish}) => {
    const {t} = useTranslation("common")
    let category: SchemaFoodMatchCategory | undefined = undefined
    for (let i = 0; i < categories.length; i++) {
        if (categories[i]["@id"] === selected) {
            category = categories[i]
            break
        }
    }

    if (category === undefined) {
        // FIXME: log this
        return null
    } else {
        return (
            <div className="container grid-lg">
                <div className="fm-selection">
                    <div className="selection-lead">
                        {t("fm/selection/dishCategory")}
                    </div>
                    <div className="selection-title">
                        <select value={selected} onChange={e => {
                            let nextCategory: string | undefined = undefined
                            for (let i = 0; i < categories.length; i++) {
                                if (categories[i]["@id"] === e.target.value) {
                                    nextCategory = categories[i]["@id"]
                                    break
                                }
                            }
                            setCategory(nextCategory)
                            setDish(undefined)
                        }}>
                            {categories.slice().sort((a: SchemaThing, b: SchemaThing) => {
                                return (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1
                            }).map(cat => (
                                <option key={cat["@id"]} value={cat["@id"]}>{cat.name}</option>
                            ))}
                        </select>
                        <FontAwesomeIcon icon={faCaretDown} fontSize={"0.8rem"} color={"#d1d1d1"}/>
                    </div>
                </div>
            </div>
        )
    }
}

const FoodMatchCategorySection: FC<FoodMatchCategorySectionProps> = (props) => {
    return (
        <section className="fm-first-step">
            {props.selected === undefined ? (<CategorySelector {...props}/>) : (<CategoryDisplay {...props}/>)}
        </section>
    )
}

interface FoodMatchDishSectionProps {
    dishes: SchemaFoodMatchDish[]
    selected: SchemaFoodMatchDish | undefined
    category: string | undefined
    setDish: (dishId: SchemaFoodMatchDish | undefined) => void
}

const DishSelector: FC<FoodMatchDishSectionProps> = ({dishes, selected, category, setDish}) => {
    const {t} = useTranslation("common")
    return (
        <div className="container grid-xl">
            <div className="h3">{t("fm/dishTitle")}</div>
            <div className="dish-container">
                {dishes.slice().sort((a: SchemaFoodMatchDish, b: SchemaFoodMatchDish) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                }).filter(dish => {
                    if (dish.wineBottles.length === 0)
                        return false
                    return dish.category["@id"] === category
                }).map(dish => (
                    <div
                        className={`dish-item ${dish["@id"] === selected?.["@id"] ? "active" : ""}`}
                        key={dish["@id"]}
                        onClick={() => setDish(dish)}>
                        <FontAwesomeIcon icon={faUtensils} className="mr-2" color={"#6c6c6c"}/>
                        {dish.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

const DishDisplay: FC<FoodMatchDishSectionProps> = ({selected, dishes, setDish, category}) => {
    const {t} = useTranslation("common")
    if (selected === undefined) {
        // FIXME: log this
        return null
    } else {
        return (
            <div className="container grid-lg">
                <div className="fm-selection">
                    <div className="selection-lead">
                        {t("fm/selection/dish")}
                    </div>
                    <div className="selection-title">
                        <select value={selected["@id"]} onChange={e => {
                            let nextDish: SchemaFoodMatchDish | undefined = undefined
                            for (let i = 0; i < dishes.length; i++) {
                                if (dishes[i]["@id"] === e.target.value) {
                                    nextDish = dishes[i]
                                    break
                                }
                            }
                            setDish(nextDish)
                        }}>
                            {dishes.filter(a => a.category["@id"] === category).slice().sort((a: SchemaThing, b: SchemaThing) => {
                                return (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1
                            }).map(dish => (
                                <option key={dish["@id"]} value={dish["@id"]}>{dish.name}</option>
                            ))}
                        </select>
                        <FontAwesomeIcon icon={faCaretDown} fontSize={"0.8rem"} color={"#d1d1d1"}/>
                    </div>
                </div>
            </div>
        )
    }
}

const FoodMatchDishSelection: FC<FoodMatchDishSectionProps> = (props) => {
    if (props.category === undefined)
        return null
    return (
        <section className="fm-first-step">
            {props.selected === undefined ? (<DishSelector {...props}/>) : (<DishDisplay {...props}/>)}
        </section>
    )
}

interface FoodMatchWineListProps {
    dish: SchemaFoodMatchDish | undefined
    onReset: () => void
}

const FoodMatchWineList: FC<FoodMatchWineListProps> = ({dish, onReset}) => {
    const {t} = useTranslation("common")
    if (dish !== undefined) {
        return (
            <section>
                <div className="h3">{t("fm/suggestionsTitle_AMOUNT").replace("%AMOUNT", `${dish.wineBottles.length}`)}</div>
                <div className="container grid-lg fm-wine-list">
                    <div className="fm-wine-list case-wine-list">
                        {dish.wineBottles.map(bottle => (
                            <div key={bottle["@id"]}>
                                {<WineEntry reference={bottle}/>}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="text-center" style={{"marginTop": "2rem"}}>
                    <button type="button" className="btn btn-reset" onClick={onReset}>
                        {t("fm/buttonRestart")}
                        <FontAwesomeIcon icon={faRotateLeft} className="ml-2"/>
                    </button>
                </div>
            </section>
        )
    } else {
        return null
    }
}

const FoodMatchPage: FC = () => {

    const categories = useFoodMatchDishCategories()
    const dishes = useFoodMatchDishes()
    const {t} = useTranslation("common")

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined)
    const [selectedDish, setSelectedDish] = useState<SchemaFoodMatchDish | undefined>(undefined)

    if (categories.busy || categories.error || dishes.busy || dishes.error)
        return null

    return (
        <div id="foodMatch">

            <section className="fm-header">
                <div className="container grid-xl">
                    <h1>{t("fm/foodMatchTitle")}</h1>
                    <div className="fm-lead">{t("fm/foodMatchDescription")}</div>
                </div>
            </section>
            <FoodMatchCategorySection setCategory={setSelectedCategory}
                                      selected={selectedCategory}
                                      categories={categories.list}
                                      dishes={dishes.list}
                                      setDish={setSelectedDish}/>
            <FoodMatchDishSelection dishes={dishes.list}
                                    selected={selectedDish}
                                    category={selectedCategory}
                                    setDish={setSelectedDish}/>
            <FoodMatchWineList dish={selectedDish} onReset={() => {
                setSelectedDish(undefined)
                setSelectedCategory(undefined)
            }}/>
        </div>
    )
}

export default FoodMatchPage