import React, {FC, useEffect, useState} from "react"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import {CheckoutWithOrderProps} from "./CheckoutRoutes"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {useCart, useCartDispatch} from "../_contexts/CartContext"
import {cartClear} from "../_services/cart.service"

const CheckoutProcessingPage: FC<CheckoutWithOrderProps> = ({order, token}) => {

    const {t, i18n} = useTranslation("checkout")
    const cart = useCart()
    const dispatch = useCartDispatch()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (cart.busy) return
        if (cart.items.length > 0 && order.paymentMethod?.identifier === "cash") {
            dispatch(cartClear())
            setLoading(false)
        } else {
            setLoading(false)
        }
        if (order.paymentMethod?.identifier !== "cash" && order.paymentIntegration) {
            window.location.href = order.paymentIntegration.checkoutURL
        }
    }, [cart, order, dispatch])

    if (loading || cart.busy) return <Loader/>

    if (order.paymentMethod?.identifier === "cash" || order.confirmationNumber) {
        return (
            <LocaleRedirect to={"/orders/" + order.orderNumber + "?t=" + token}/>
        )
    }

    // TODO: translate this
    if (!order.paymentIntegration) {
        return (
            <div>
                Something went wrong. Payment not found.
            </div>
        )
    }

    return (
        <div>
            Redirecting to payment provider... If nothing happens click <a href={order.paymentIntegration.checkoutURL}>here</a>.
        </div>
    )
}

export default CheckoutProcessingPage
